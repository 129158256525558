import styled from 'styled-components';
import {Modal} from "react-bootstrap";
const BoldFont = 'Rubik-Medium';
const MediunFont = 'Rubik-Regular'
import { ImageInput } from '../CreateEvent/styles';

const StyledModal = styled(Modal)`
  .modal-dialog {
    .modal-content {
      background-color: #F6F6F8;
      border: none;
      width: ${({bulkWidth}) => bulkWidth && '400px' };
      left: ${({left}) => left && '80px'};
    }
    .modal-header {
      border-bottom: 0px;
    } 
  }
`;

const StyledModalNew = styled(Modal)`
  .modal-dialog {
    .modal-content {
      background-color: #F6F6F8;
      border: none;
      width: ${({bulkWidth}) => bulkWidth && '400px' };
      left: ${({left}) => left && '80px'};
      .modal-body {
        padding: 15px 25px 25px 25px;
      }
    }
    .modal-header {
      border-bottom: 0px;
    } 
  }
`;

const StyledmodalV2 = styled(Modal)`
  .modal-dialog {
    .modal-content {
      background-color: #F6F6F8;
      border: none;
      width: ${({bulkWidth}) => bulkWidth && '485px' };
      left: ${({left}) => left && '38px'};
    }
    .modal-header {
      border-bottom: 0px;
    } 
  }
`;

const StyledHeader = styled(Modal.Header)`
  padding: ${({titlePadding}) => titlePadding ? '25px 25px 0px 15px' : '25px 25px 0px 25px' };
  // width: 600px;
  height: 50px;
background: #005C87;
border-radius: 6px 6px 0px 0px;
  .btn-close{
    display: none;
  }
  .close {
    // float: right;
    // margin-top: -20px;
    // margin-right: -16px;
    //background-image:  url('/images/CompanyDashBoardV2/NewIcon/new_close_icon.png'); 

      float: right;
      margin-top: -21px;
      margin-right: 2px;
     color: white;
     font-size: 44px;
      opacity: 0.9;
     display: none;
   }
`;

const StyledHeaderNew = styled(Modal.Header)`
  padding: ${({titlePadding}) => titlePadding ? '25px 25px 0px 15px' : '25px 25px 0px 25px' };
  // width: 600px;
  height: 50px;
background: #005C87;
border-radius: 6px 6px 0px 0px;
  .btn-close{
    display: none;
  }
  .close {
    // float: right;
    // margin-top: -20px;
    // margin-right: -16px;
    //background-image:  url('/images/CompanyDashBoardV2/NewIcon/new_close_icon.png'); 

      float: right;
      margin-top: -21px;
      margin-right: 2px;
     color: white;
     font-size: 44px;
      opacity: 0.9;
     display: none;
   }
`;


const StyledBody = styled(Modal.Body)`
  padding: 0px 25px 25px 25px;
`;

const StyledBodyNew = styled(Modal.Body)`
  padding: 0px 25px 25px 25px;
`;

const StyledBodyV2 = styled(Modal.Body)`
  padding: 0px 0px 25px 50px;
  height: 98px;
`;

const Container = styled.div`
  width: ${({maxWidth})=>maxWidth? maxWidth: "80%"};
  margin-left: ${({maxWidth})=>maxWidth? 0: "10%"};
  width: ${({bulkWidth})=> bulkWidth && "80%"};
  position: relative;
  display: flex;
  flex-direction: column;
  .headerName{
    //text-align: center;
    //color: #67676A;
    margin-bottom: 5px;
    //font-family: ${BoldFont};
    position: relative;
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    right: ${({right}) => right ? '0px' : '64px'};
    color: white;
  }
  .divider{
    border: 1px solid #9C9C9C60;
  }
  .subTitle{
    font-size: 14px;
    letter-spacing: 0px;
    color: rgba(103,103,106,0.5);
    font-family: ${MediunFont};
    text-align: center;
  }
   .cross_img {
    float: right;
    position: relative;
    bottom: 30px;
    left: ${({leftCross}) => leftCross ? '350px' : '390px'};
     > img {
       width: 20px;
       height: 20px;
     }
   }
  @media (max-width: 720px) {
    width: 100%;
    margin: 0px;
  }
`;

const ContainerNew = styled.div`
  width: ${({maxWidth})=>maxWidth? maxWidth: "100%"};
  width: ${({bulkWidth})=> bulkWidth && "100%"};
  display: flex;
  flex-direction: column;
  .headerName{
    margin-bottom: 5px;
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: white;
  }
  .divider{
    border: 1px solid #9C9C9C60;
  }
  .subTitle{
    font-size: 14px;
    letter-spacing: 0px;
    color: rgba(103,103,106,0.5);
    font-family: ${MediunFont};
    text-align: center;
  }
   .cross_img {
    width: 20px;
    height: 20px;
    display: flex;
    margin: auto;
    margin-right: 0px;
     > img {
       width: 20px;
       height: 20px;
     }
   }
  @media (max-width: 720px) {
    width: 100%;
    margin: 0px;
  }
`;

const ContainerV2 = styled.div`
  width: ${({maxWidth})=>maxWidth? maxWidth: "80%"};
  margin-left: ${({maxWidth})=>maxWidth? 0: "10%"};
  width: ${({bulkWidth})=> bulkWidth && "80%"};
  position: relative;
  .headerName{
    //text-align: center;
    //color: #67676A;
    margin-bottom: 5px;
    //font-family: ${BoldFont}
    bottom: 13px;
    position: relative;
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    right: ${({right}) => right ? '0px' : '64px'};
    color: white;
    left: 40px;
    text-align: center;
  }
  .divider{
    border: 1px solid #9C9C9C60;
    margin-bottom: 25px;
  }
  .subTitle{
    font-size: 14px;
    letter-spacing: 0px;
    color: rgba(103,103,106,0.5);
    font-family: ${MediunFont};
    text-align: center;
  }
   .cross_img {
    float: right;
    position: relative;
    bottom: 40px;
    left: 95px;
     > img {
       width: 20px;
       height: 20px;
     }
   }
  @media (max-width: 720px) {
    width: 100%;
    margin: 0px;
  }
`;

const InputContainer = styled.div`
  //width: 90%;
  right: ${({right}) => right ? '0px' : '78px'} ;
  width: 450px;
  margin-left: ${({marginLeft}) => marginLeft ? '0px' : '5%'};
  ${'' /* margin-top: ${({marginTop})=> marginTop ? '0px' : '20px'}; */}
  margin-bottom:  ${({marginBottom})=> marginBottom ? '0px' : '20px'};
  position: relative;
  text-align: ${({Align}) => Align ? 'center': 'left'};
  display: flex;
  flex-direction: row;
  > div {
    > button {
      width: 27px !important;
      height: 27px !important;
    }
  }
  @media(max-width: 500px) {
    width: 80%;
    margin-left: 10%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  ._react-file-reader-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }


  .delete {
  cursor: pointer;
}

 
  .bulkEmail {
    font-weight: normal;
    text-align: center;
    text-decoration: underline;
    text-decoreation-color: rgb(148,191,62);
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0px;
    color: rgb(148,191,62);
    font-family: ${BoldFont};
    margin-left: 5px;
  }
  .SuccessUpload {
    .glyphicon-trash {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .background{
      //background-color: #fff;
      //cursor: pointer;
      width: 200px;
      height: 50px;  
     background: #9FC989;
     border-radius: 5px;
     margin-left: 15px;
  }
  >i {
    display: inline-block;
    position: absolute;
    top: 26px;
    font-size: 20px;
    right: 30px;
    cursor: pointer;
    color: rgba(29,29,27,0.5); 
  }
  > div {
    display: ${({display})=> display ? 'flex' : 'inline-block'};
    // display: inline-block;
    align-items: center;
    font-weight: bold;
    padding: ${({paddingtext}) => paddingtext ? '0px' : '12px'};
    border-radius: 4px;
    .addButton {
      padding: 0px;
      border: none;
      font-size: 20px;
      letter-spacing: 0px;
      color: rgb(255,255,255);
      width: 27px !important;
      height: 27px !important;
      border-radius: 50%;
      background-color: #93bf3d;
      display: inline-block;
      margin: 0px;      
      text-align: center;
      cursor: pointer;
      font-family: ${BoldFont};
      float: left;
    }
    .addMail {
      display: inline-block;
      //margin-left: 10px;
      font-size: 12px;
      letter-spacing: 0px;
      //color: rgb(51,51,51);
      color: white;
      font-family: ${BoldFont};
      //margin-top: 4px;
      position: relative;
      top: 6px;
      font-family: Rubik-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

const InputContainerNew = styled.div`
  width: 100%;
  ${'' /* margin-top: ${({marginTop})=> marginTop ? '0px' : '20px'}; */}
  margin-bottom:  ${({marginBottom})=> marginBottom ? '0px' : '20px'};
  text-align: ${({Align}) => Align ? 'center': 'left'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  > div {
    > button {
      width: 27px !important;
      height: 27px !important;
    }
  }
  @media(max-width: 500px) {
    width: 80%;
    margin-left: 10%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  ._react-file-reader-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }
  .bulkEmail {
    font-weight: normal;
    text-align: center;
    text-decoration: underline;
    text-decoreation-color: rgb(148,191,62);
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0px;
    color: rgb(148,191,62);
    font-family: ${BoldFont};
  }
  .SuccessUpload {
    margin-left:15px;
    .glyphicon-trash {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .background{
      //background-color: #fff;
      //cursor: pointer;
      width: 200px;
      height: 50px;  
     background: #007AB1;
     border-radius: 5px;
     display: flex;
     justify-content: center;
     
     :hover{
      background:#005C87;
      color:#ffff;
}
  }
  >i {
    display: inline-block;
    position: absolute;
    top: 26px;
    font-size: 20px;
    right: 30px;
    cursor: pointer;
    color: rgba(29,29,27,0.5); 
  }
  > div {
    display: ${({display})=> display ? 'flex' : 'inline-block'};
    // display: inline-block;
    align-items: center;
    font-weight: bold;
    border-radius: 4px;
    .addButton {
      padding: 0px;
      border: none;
      font-size: 20px;
      letter-spacing: 0px;
      color: rgb(255,255,255);
      width: 27px !important;
      height: 27px !important;
      border-radius: 50%;
      background-color: #93bf3d;
      display: inline-block;
      margin: 0px;      
      text-align: center;
      cursor: pointer;
      font-family: ${BoldFont};
      float: left;
    }
    .addMail {
      display: inline-block;
      //margin-left: 10px;
      font-size: 12px;
      letter-spacing: 0px;
      //color: rgb(51,51,51);
      color: white;
      font-family: ${BoldFont};
      //margin-top: 4px;
      font-family: Rubik-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      

    }
  }
`;

const StyledInputContainer=styled.div`
position:relative;
width: 92%;
max-width:500px;
display: flex;
padding-bottom:0 !important;
padding-left:15px !important;

  margin-bottom: 0;
  margin-right:24px;
.edit{
  position:absolute;
  width: 22px;
  height:22px;
  right: 27px;
  top: 25px
 
  
    }
`




const StyledInput = styled.input`
  //background-color: white;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  padding: 15px;
  font-size: 16px;
  color: rgb(139,139,139);
  width: 100%;
  color: #0D4270;
  background: #FFFFFF;
  border: 1px solid #9C9C9C;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Rubik-Regular;
  margin-top: ${({marginTop}) => marginTop ? '10px' : '0px'}
  ::placeholder {
    color: #9C9C9C;
    font-family: ${MediunFont};
    font-size: 16px;
  }
`;

const StyledInputNew = styled.input`
  //background-color: white;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  padding: 15px;
  font-size: 16px;
  color: rgb(139,139,139);
  width: 100%;
  color: #0D4270;
  background: rgba(156, 156, 156, 0.1);
  border: 1px solid #9C9C9C;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Rubik-Regular;
  margin-top: ${({marginTop}) => marginTop ? '10px' : '0px'}
  ::placeholder {
    color: #9C9C9C;
    font-family: ${MediunFont};
    font-size: 16px;
  }
`;

const ViewLocations = styled.input`
  background: none;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  padding: 15px;
  font-size: 16px;
  color: rgb(139,139,139);
  width: 100%;
  color: #0D4270 !important;
  font-family: Rubik-Regular;
  margin-top: ${({marginTop}) => marginTop ? '10px' : '0px'}
  ::placeholder {
    color: #9C9C9C;
    font-family: ${MediunFont};
    font-size: 16px;
  }
`;



const SubmitContainer = styled.div`
  width: 100%;
  //margin-left: 15%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({padding}) => padding ? '0px 0px' : '10px 0px'}
  // .send {
  //   font-size: 14px;
  //   letter-spacing: 0px;
  //   color: rgb(255,255,255);
  //   font-family: ${BoldFont}
  //   font-weight: bold;
  // }
`;

const SubmitContainerNew = styled.div`
  width: 100%;
  //margin-left: 15%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({padding}) => padding ? '0px 0px' : '10px 0px'}
  // .send {
  //   font-size: 14px;
  //   letter-spacing: 0px;
  //   color: rgb(255,255,255);
  //   font-family: ${BoldFont}
  //   font-weight: bold;
  // }
`;

const InputWrapper = styled(ImageInput)`
  padding: 0px;
  margin-top: 0px;
  // color: rgb(148,191,62);
  // text-transform: uppercase;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #69C2FF;
`;

const InputWrapperNew = styled(ImageInput)`
  padding: 0px;
  margin-top: 0px;
  // color: rgb(148,191,62);
  // text-transform: uppercase;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #007AB1;
`;

const FieldTitle = styled.span`
  letter-spacing: 0px;
  color: ${({color}) => color ? color :'#20495E'};
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  font-family: Rubik-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  top: 4px;
  position: relative;
  //right: 76px;
  padding-top: ${({paddingTop})=> paddingTop ? '15px' : '0px'}
  margin-bottom: ${({marginBottom}) => marginBottom ? '10px' : '0px'}
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const FieldTitleNew = styled.span`
  letter-spacing: 0px;
  color: ${({color}) => color ? color :'#005C87'};
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  font-family: Rubik-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  top: 4px;
  position: relative;
  //right: 76px;
  padding-top: ${({paddingTop})=> paddingTop ? '15px' : '0px'}
  margin-bottom: ${({marginBottom}) => marginBottom ? '10px' : '0px'}
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const FieldTitleV2 = styled.span`
  letter-spacing: 0px;
  color: ${({color}) => color ? color :'#20495E'};
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  font-family: Rubik-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  // top: 4px;
  position: relative;
  //right: 76px;
  padding-top: 0px;
  margin-bottom: ${({marginBottom}) => marginBottom ? '10px' : '0px'}
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const InputContainerr = styled.div`
  width: 100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
padding-left:0 !important;
  margin-top: 15px;
  text-align: ${({Align}) => Align ? 'center': 'left'};
  .addMoreButton{
    //background-color: #fff;
    cursor: pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    width: 200px;
    height: 50px;  
    text-align:center;
   background: #007AB1;
   border-radius: 5px;
   font-family: 'Rubik-Medium';
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 18px;
   text-align: center;  
   color: #FFFFFF;

 
:hover{
  background:#005C87;
  color:#ffff;
}

}
`;

const StyledModalV2 = styled(Modal)`
  .modal-backdrop.in{
    opacity: .2;
  }
  .modal-dialog {
    transform: translate(-50%, -50%);
  }
  .delete-popup {
    width: 300px; 
    top: 30%;
    margin: 0 auto;
    .modal-header {
      border-bottom: 0px;
      background: linear-gradient( 
        180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0 0;
    } 
  }
 @media (max-width: 500px){
   .modal-dialog {
    width: 80%;
    }
 }
`;

const BoldV2 = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 25px;
    color: #0D4270;
    >span{
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 25px;
      margin-top: 25px;
      color: #0D4270;
    }
  .subContent {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #9C9C9C;
    margin-top: 13px;
  }
`;

const StyledModalV3 = styled.div`
  width: 100% !important;
  position: fixed;
  background:white;
  display:${({show})=>show?"flex":"none"};
  z-index:101;
  bottom:0;
  border-radius: 0px;
  margin:0;
  right: -68px;
  .modal-dialog {
    width: 88%;
    margin-left: 120px;
    margin-top: 15%;
    .modal-content {
      background-color: #F6F6F8;
      border: none;
    }
    .modal-header {
      border-bottom: 0px;
    } 
  }
`;

const LocationPopup = styled.div`
display: flex;
width: 100%;
height: 80px;
.popup-class{
  width:45%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 10px;
  padding-left: 35px;
  .outer-div{
    display: flex;
    width: 74%;
    >div{
      width: 46px;
      height: 30px;
      background: #0D4270;
      border: 2px solid #F2F2F2;
      border-radius: 15px;
      font-family: 'Rubik-Regular';
      font-size: 12px;
      line-height: 14px;
      text-align: center; 
      color: #FFFFFF;
      padding-top: 8px;
    }
  }
}
.popup-title{
  font-family: 'Rubik-Regular';
  font-size: 18px;
  line-height: 24px;
  color: #0D4270; 
  width: 100%;
  margin-bottom: 5px;
  display: flex;
}
.inner-div{
  display: flex;
  width: 100%;
  // align-items: center;
  // justify-content: flex-start;

  img{
    width: 30px;
    height: 30px;
    border: 2px solid #F3F6F9;
    border-radius: 50%;
    margin-right: 5px;
    margin-bottom: 11px;
  }
  >div{
    width: 46px;
    height: 30px;
    background: #0D4270;
    border: 2px solid #F2F2F2;
    border-radius: 15px;
    font-family: 'Rubik-Regular';
    font-size: 12px;
    line-height: 14px;
    text-align: center; 
    color: #FFFFFF;
    padding-top: 8px;
  }
  >h1{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9C9C9C;
    margin: 0px;
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
}
`;

const AddLocationButtonn = styled.button`
display: flex;
width: 200px;
height: 50px;
Cursor: pointer;
background: ${({background}) => background ? background : '#FD7175'};
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
border-radius: 3px;
border: none;
font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
color:#FFFFFF;
justify-content: center;
align-items: center;
margin: 15px 15px 15px 0px;
opacity: ${({disabled}) => disabled && '0.5'}

`;

const AddCancelButtonn = styled.div`
width: 200px;
height: 50px;
left: 1482px;
top: 720px;
border: 1px solid #9C9C9C;
border-radius: 3px;
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
justify-content: center;
color: #9C9C9C;
cursor: pointer
`;

const UserButtonn = styled.button`
  background-color: ${({bgColor}) => bgColor ? bgColor: ''};
  text-transform: ${({capitalize}) => capitalize ? 'capitalize' : 'uppercase' };
  cursor: pointer;
  color: ${({textColor}) => textColor ? textColor : 'white'};
  white-space: nowrap;
  padding: ${({padding}) => padding ? padding : '4px 7px'};
  width: ${({width}) => width ? width: '100%'};
  font-size: ${({fontSize}) => fontSize ? fontSize : '9px'};
  line-height: 1.428571429;
  border-radius:  ${({radius}) => radius ? radius : '5px'};
  font-family:  ${({font}) => font ? font : `${BoldFont}`};
  font-weight: ${({font}) => font && '600'};
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '0px'};
  float: ${({float}) => float ? 'left': 'right'};
  margin-top: ${({marginTop}) => marginTop ? marginTop : '0px'};
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'};
  text-align: ${({Align}) => Align ? 'center': 'left'};
  opacity: ${({bgOpacity}) => bgOpacity ? bgOpacity: 1};
  border: ${({border}) => border ? '1px solid lightgray': 'none'};
  height: ${({ height }) => height ? height : '46px'};
  box-sizing:  ${({ boxSizing}) => boxSizing ? "border-box" : 'none'};
  opacity: ${({disabled}) => disabled && '0.5'};
  img {
    height: 18px;
    width: 18px;
  }

  &:hover, &:active, &:focus{
    outline: ${({outline}) => outline ? '' : 'none'};
  }

  .send 
  {
text-transform: capitalize;   
font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;
color: #FFFFFF;
  }
`;

const StyledBodyV3 = styled(Modal.Body)`
padding: 0px 25px 25px 99px;
margin-top: 10px;
font-size: 16px;
`;

const NewEmployeeFields = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  span {
    margin: 10px 0px;
  }
`;

const NewEmployeeFieldsNew = styled.div`
  display: flex;
  flex-direction: column;
  span {
    margin: 10px 0px;
  }
`;

const PopupHeaderNew = styled.div`
  display: flex;
  width:100%;
  justify-content: space-between;
  .headingTitle{
    font-family: "Rubik-Medium";
    font-size: 18px;
    line-height: 24px;
    color:white;
  }
  .closeButton{
    width:20px;
    height:20px;
    >img{
      width:20px;
      height:20px;
      cursor:pointer;
    }
  }
`;

const StyledInputNewP1 = styled.input`
  //background-color: white;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  padding: 15px;
  font-size: 16px;
  color: rgb(139,139,139);
  width: 100%;
  color: #0D4270;
  background: #ffff;
  border: rgba(0, 92, 135, 0.3);
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Rubik-Regular;
  margin-top: ${({marginTop}) => marginTop ? '10px' : '0px'}
  ::placeholder {
    color: #005C87;
    opacity:30%;
    font-family: ${MediunFont};
    font-size: 16px;
  }
`;




export { StyledModal, StyledBody, StyledHeader, Container, InputContainer, StyledInputContainer,
  StyledInput, SubmitContainer, InputWrapper,FieldTitle, InputContainerr, StyledModalV2, BoldV2, LocationPopup, StyledModalV3, AddLocationButtonn, ViewLocations, FieldTitleV2, StyledBodyV2, StyledmodalV2, ContainerV2, AddCancelButtonn, UserButtonn, StyledBodyV3, NewEmployeeFields, PopupHeaderNew,
  InputWrapperNew, StyledModalNew, StyledBodyNew, StyledHeaderNew, ContainerNew, StyledInputNew, InputContainerNew, SubmitContainerNew,FieldTitleNew, NewEmployeeFieldsNew, StyledInputNewP1
};